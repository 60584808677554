<script setup lang="ts">

import {useHomePageStore} from "~/stores/mainStore";
import {useThrottleFn} from "@vueuse/core";

const homePageStore = useHomePageStore();
const currentSelectedProduct = ref(1);
const selectedProduct = ref();

const stepCurrentSelectedProduct = useThrottleFn(()=>{
	if (currentSelectedProduct.value >= homePageStore.products.length) {
		currentSelectedProduct.value = 1;
	}else {
		currentSelectedProduct.value++;
	}
}, 5000);


</script>

<template>
	<div class="relative bg-white">
		<!-- Slogan Section -->
		<section class="relative min-h-[390px] lg:min-h-dvh">
			<NuxtImg class="w-full min-h-[390px] lg:min-h-dvh max-h-dvh object-cover"
			         sizes="100vw xl:100vw"
			         placeholder
			         preload
			         alt="Product picture"
			         densities="x1 x2 x3"
			         src="/images/home_3.jpg" />
			<div
				class="absolute w-full h-fit mx-auto p-4 md:p-8 text-center
			    md:flex md:flex-col
			    md:px-10 lg:px-24 xl:px-40 md:min-w-[640px] lg:min-w-[760px]
			    md:rounded-tr-sm md:rounded-br-[5rem] md:w-auto left-0 top-auto bottom-5 bg-white bg-opacity-80 md:bg-opacity-95"
			>
				<h1 class="text-2xl md:text-5xl font-bold text-secondary mb-4 text-left">EvoNap</h1>
				<p class="text-[14px] md:text-lg font-fjalla text-start text-secondary md:mb-6">Quick Nap, <span class="not-italic font-semibold">Big Impact</span></p>
				<NuxtLink to="/join-us"
				          class="hidden md:inline text-center
			           bg-primary text-black hover:bg-[#ffdb84]
			           h-10 w-24 p-2 rounded-lg font-medium"
				>
					Join Us
				</NuxtLink>
			</div>
		</section>

		<!-- Product Information -->
		<section class="bg-white py-16 md:py-32">
			<div class="@container mx-auto">
				<div class="flex @5xl:flex-row @5xl:px-0 px-8 flex-col-reverse justify-center gap-10 max-w-screen-lg mx-auto">
					<div class="flex flex-col my-auto text-start @5xl:max-w-96">
						<h2 class="text-2xl text-nowrap font-semibold text-black mb-4">Rest is Important</h2>
						<p class="text-base text-black prose text-justify">
							EvoNap creates innovative napping pods designed to improve comfort, well-being, and productivity in various environments. Our mission is to reduce fatigue and boost mental clarity, making rest more accessible in busy spaces. Whether in corporate offices, airports, or public areas, EvoNap provides a restful escape for individuals on the go. We focus on promoting health and relaxation to enhance daily performance.
<!--							<br /><span class="font-semibold">Discover the future of rest with EvoNap.</span>-->
						</p>
					</div>
					<div class="w-auto my-auto">
						<NuxtImg
							class="mx-auto rounded-lg max-w-full max-h-64 @5xl:max-h-72 @7xl:max-h-80"
							src="/images/sleep_banner.jpg"
							placeholder
							height="300"
							sizes="100vw xl:50vw"
							alt="Sleep Banner"
						/>
<!--						<video-->
<!--							controls autoplay muted loop-->
<!--							class="mx-auto rounded-lg max-w-full max-h-64 @5xl:max-h-72 @7xl:max-h-80"-->
<!--							src="/videos/SampleVideo_1280x720_10mb.mp4"></video>-->
					</div>
				</div>
			</div>
		</section>

		<section class="relative">
			<div class="absolute max-w-full w-dvw h-full">
				<NuxtImg src="/images/product_3_1920x1080.jpg"
				         class="w-full h-full"
				         sizes="100vw xl:100vw"
				         placeholder
				         preload
				         alt="Product inside picture"
				         densities="x1 x2" />
			</div>
			<div class="absolute max-w-full w-dvw h-full
			  opacity-50 bg-gradient-to-t from-transparent via-transparent from-0% via-70% to-100% to-black"
			></div>

			<div class="px-8 pt-16 pb-52 backdrop-blur-sm">
				<div class="absolute left-0 top-0 right-0 w-full h-1 bg-white"></div>
				<div class="absolute left-0 bottom-0 right-0 w-full h-24 bg-white"
				     style="clip-path: polygon(100% 101%, 100% 30%, 50% 100%, 0 30%, 0 101%);"
				></div>
				<h2 class="text-2xl lg:text-nowrap text-center mx-auto px-3 w-fit font-semibold rounded-lg text-white">
					Here's Why You'll Love Our Product
				</h2>
				<Swiper
					class="max-w-full md:w-4/5 lg:w-4/6 !h-auto !p-7 !pt-16"
					:modules="[SwiperAutoplay, SwiperPagination, SwiperNavigation, SwiperHashNavigation]"
					:slides-per-view="1"
					:space-between="32"
					:loop="true"
					:infinite="true"
					:effect="'slide'"
					:navigation="{
						enabled: true,
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					}"
					:centered-slides="false"
					:breakpoints="{
						'900': {
							slidesPerView: 1,
						},
						'1800': {
							slidesPerView: 2,
						}
					}"
					:pagination="{
						enabled: true,
						clickable: true,
						type: 'bullets',
						el: '.swiper-pagination',
					}"
					:autoplay="{
						pauseOnMouseEnter: true,
						delay: 10000,
						disableOnInteraction: true,
					}"
					:height="undefined"
					:width="undefined"
					:hash-navigation="false"
				>
					<SwiperSlide v-for="slider in homePageStore.productFeatures" :key="1" class="!h-auto">
						<div class="bg-neutral p-0 m-5 mt-0 mx-auto rounded-2xl shadow-md text-center max-w-[512px] self-stretch">
							<NuxtImg class="rounded-t-2xl object-cover" width="512" height="384" placeholder :src="slider.image" :alt="`${slider.title} image`" />
							<h3 class="py-4 font-bold">{{ slider.title }}</h3>
							<p class="text-left px-5 pb-4 text-black">{{ slider.body }}</p>
						</div>
					</SwiperSlide>
					<div class="swiper-pagination"></div>
					<div class="swiper-button-prev !-left-0 after:text-black"></div>
					<div class="swiper-button-next !-right-0 after:text-black"></div>
				</Swiper>
			</div>


		</section>

		<section class="text-center bg-white px-8 md:px-16 py-16">
			<h3 class="text-2xl font-semibold">Discover Our Products</h3>
			<div class="flex flex-row flex-wrap justify-center pt-16 gap-x-8 gap-y-4" v-auto-animate>
				<div v-for="product in homePageStore.products" :key="product.id" class="flex flex-col justify-center">
					<div @click="currentSelectedProduct=product.id; selectedProduct.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })"
						class="relative w-fit border-8 rounded-full hover:border-primary hover:bg-primary transition-colors duration-300 motion-reduce:transition-none"
						:class="{ 'border-primary bg-primary': currentSelectedProduct==product.id }">
						<NuxtImg :src="product.image" width="112" height="112" placeholder :alt="`${product.name} image`" class="w-28 h-28 object-cover rounded-full" />
					</div>
					<p class="pt-6 font-semibold">{{ product.name }}</p>
				</div>
			</div>
		</section>

		<section ref="selectedProduct" class="bg-primary px-8 md:px-16 py-16" v-auto-animate>
			<template v-for="product in homePageStore.products" :key="product.id">
				<div v-if="product.id==currentSelectedProduct" class="flex flex-col items-center">
					<Swiper
						class="w-full h-auto"
						:modules="[SwiperAutoplay, SwiperPagination, SwiperNavigation, SwiperHashNavigation]"
						:slides-per-view="1"
						:space-between="30"
						:centered-slides="false"
						:breakpoints="{
							'650': {
								slidesPerView: 2,
							},
							'1024': {
								slidesPerView: 3,
							},
							'2550': {
								slidesPerView: 'auto',
							}
						}"
						@reach-end="stepCurrentSelectedProduct"
						:loop="true"
						:infinite="true"
						:effect="'slide'"
						:autoplay="{
							pauseOnMouseEnter: true,
							delay: 1000,
							disableOnInteraction: true,
						}"
					>
						<SwiperSlide v-for="(slide, i) in product.sliders">
							<NuxtImg :src="slide.image" width="576" height="360" placeholder :alt="`${product.name} slider-${i} image`" class="w-full h-[360px] rounded-2xl object-cover" />
						</SwiperSlide>
					</Swiper>
					<p class="text-center px-0 lg:px-24 xl:px-72 pt-12 pb-12">{{ product.description }}</p>
					<NuxtLink :to="product.link" class="text-center bg-secondary text-neutral h-10 w-fit px-5 p-2 rounded-lg">Read more</NuxtLink>
				</div>
			</template>
		</section>

		<!-- Contact Section -->
		<section class="bg-white px-8 md:px-16 pb-16 md:pt-32 md:pb-32 pt-16">
			<ContactUs />
		</section>
	</div>
</template>

<style lang="postcss">
	*,
	:before,
	:after {
		--swiper-pagination-color: white;
		--swiper-pagination-bullet-inactive-opacity: 1;
		--swiper-pagination-bullet-inactive-color: transparent;
		--swiper-pagination-bottom: -5px;
	}
	.swiper-pagination-bullet {
		@apply border border-gray-400
	}
	.swiper-pagination-bullet-active {
		@apply border-0
	}
</style>
